import "../style/aboutUs.scss";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import aboutLarge from "../img/aboutUs2Large.webp";
import aboutMedium from "../img/aboutUs2Medium.webp";
import aboutSmall from "../img/aboutUs2Small.webp";
import certLarge from "../img/certificate2Large.webp";
import certMedium from "../img/certificate2Medium.webp";
import certLarge2 from "../img/certificateLarge.webp";
import certMedium2 from "../img/certificateMedium.webp";
import certSmall from "../img/certificate2Small.webp";
import certSmall2 from "../img/certificateSmall.webp";
import catL from "../img/aboutCat2L.webp";
import catM from "../img/aboutCat2M.webp";
import catS from "../img/aboutCat2S.webp";
import catXS from "../img/aboutCat2XS.webp";
import catXXS from "../img/aboutCat2XXS.webp";

function AboutUs(props) {
  useEffect(() => {
    if (props.isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!props.isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [props.isKitten]);
  return (
    <>
      <Helmet>
        <title>
          About Us | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
        <link rel="canonical" href="https://annanoahcattery.com/about" />
      </Helmet>
      <main className="about-us">
        <h1 className="about-us__title">About Us</h1>
        <div className="about-us__text-container">
          <article className="about-us__data-container-text">
            <h2 className="about-us__text about-us__important about-us__text--title">
              Who We Are ?
            </h2>
            <p className="about-us__text about-us__text--one">
              {/* We are small home breeding cattery of Ragdoll cats based in
              Carterton , Oxfordshire . I would like to share with you my hobby
              and passion for Ragdolls and how they have become part of my life
              … */}
              Annanoah Ragdoll Cattery is registered with both FiFe and TICA.We
              strive to produce beautiful, extremely healthy and perfectly
              socialised kittens in our beautiful home in the Cotswolds , United
              Kingdom. My name is Anna and here l would like to share with you
              my passion for cats … especially Ragdoll cats and how they have
              become a big part of my life …
            </p>
            <p className="about-us__text about-us__text--two">
              {/* I bought my first Ragdoll in November 2017. Blue mitted male in
              pet option. At that time l didn’t think to establish a cattery but
              over the time spending my afternoons doing research about the
              breed and their history l became addicted to it! My ragdoll was
              growing up and l carefully watched him day by day. I made the
              decision that l would love to breed these beautiful cats. */}
              I bought my first Ragdoll in November 2016. A blue mitted male in
              pet option. At that time l had no thought to establish a cattery,
              but over the time spending my afternoons researching the breed and
              its history l became addicted to it! My ragdoll was growing up and
              l carefully watched him day by day. I made the decision that l
              would love to breed these beautiful cats.
            </p>
            <p className="about-us__text about-us__text--three">
              {/* The next step towards breeding was to gain education and
              knowledge. This included the background of Ragdolls and
              understanding the feline reproduction and neonatal care required. */}
              The next step towards breeding was to gain education and
              knowledge. This included the background of Ragdolls and
              understanding the feline reproduction and neonatal care required.
              Some time after that I began to look for cats with the best
              characteristics and that also meet the breed standard of Fife and
              TICA from around Europe and North America to join my breeding
              program and to build up relationships and trust with other
              breeders.
            </p>
            <p className="about-us__text about-us__text--four">
              {/* Some time after that I began to look for cats with the best
              characteristics and that also meet the breed standard of Fife from
              around Europe and North America to join my breeding program and to
              build up relationships and trust with other breeders. */}
            </p>
            <div className="about-us__owner-container">
              <picture className="about-us__picture">
                <source media="(min-width: 1024px)" srcSet={aboutLarge} />
                <source media="(min-width: 600px)" srcSet={aboutMedium} />
                <img
                  className="about-us__owner about-us__img"
                  src={aboutSmall}
                  alt="Annanoah is holding the Ragdoll cat"
                />
              </picture>
            </div>
          </article>
          <article className="about-us__certificate-wrapper">
            <h2 className="about-us__text about-us__important">
              We Are Associated !
            </h2>

            <p className="about-us__text">
              {/* Annanoah*GB is registered with Strictly Cats under the patronage
              Felis Britannica and the world’s largest Federation of Feline
              Fines (Federation Internationale Feline). */}
              Annanoah*GB is registered with Strictly Cats under the patronage
              Felis Britannica and the world’s largest Federation of Feline
              Fines (Federation Internationale Feline) FIFe , also we are
              registered with TICA the world’s largest genetic registry of
              pedigreed cats The International Cat Association® (TICA®).
            </p>
            <p className="about-us__text">
              Link to the club:
              <a
                href="https://www.strictlycats.org/ragdoll"
                className="about-us__link"
                target="_blank"
                rel="noreferrer noopener nofollow"
              >
                Strictly Cats
              </a>
            </p>

            <div className="about-us__certificate-container">
              <picture className="about-us__picture">
                <source media="(min-width: 1024px)" srcSet={certLarge2} />
                <source media="(min-width: 600px)" srcSet={certMedium2} />
                <img
                  className="about-us__certificate about-us__img"
                  src={certSmall2}
                  alt="Annanoah *GB RAGDOLL CATTERY UK"
                />
              </picture>
            </div>
            <div className="about-us__certificate-container">
              <picture className="about-us__picture">
                <source media="(min-width: 1024px)" srcSet={certLarge} />
                <source media="(min-width: 600px)" srcSet={certMedium} />
                <img
                  className="about-us__certificate about-us__img"
                  src={certSmall}
                  alt="Annanoah *GB RAGDOLL CATTERY UK"
                />
              </picture>
            </div>
          </article>
          <article className="about-us__cats-wrapper">
            <h2 className="about-us__text about-us__important">Our cats !</h2>
            <p className="about-us__text">
              {/* Our Ragdoll kittens are raised underfoot in a loving home, with
              the help of family and monitored 24/7! We are focused on breeding
              healthy and well socialised cats. We have constant contact with a
              veterinarian providing comprehensive care, behaviourists and
              breeders! We also strive to have no more than 2 litters. */}
              Annanoah kittens are health guaranteed, fully vaccinated and
              registered with TICA. All our kittens are raised underfoot in our
              home and we make no compromises. Kittens are fed on premium
              quality food and we work closely with the local butcher to get the
              best meat from approved local farms ! Our kittens are perfectly
              socialised with our family including dogs and cats ! We have been
              working with very experienced veterinarians over the years. The
              Bridge Veterinary Clinic based in Lechlade, Cotswolds provides us
              with comprehensive care and with whom we share a fantastic
              relationship.
            </p>

            <p className="about-us__text">
              {/* We only have a small number of litters per year, as we feel the
              quality of our Ragdoll kittens is more important than the
              quantity. We also strive at having no more than 2 litters at one
              time to ensure individual attention. Our Ragdoll females also have
              plenty of relaxing time in-between litters, we do not believe in
              over breeding.Our Ragdoll kittens have the run of the home and can
              usually be found playing and running around the house! Kittens at
              Annanoah are raised as members of our family till they become a
              member of yours. Breeding is free from genetic disease (HCM, PKD)
              and viral disease (FIV, FELV). */}
              We only have a small number of litters per year, as we feel that
              the quality of our Ragdoll kittens is more important than the
              quantity. We also strive to have no more than 2 litters at any one
              time to ensure individual attention. Our Ragdoll females also have
              plenty of relaxing and recovery time in-between litters, we do not
              believe in over breeding. Our Ragdoll kittens have the run of our
              home and can usually be found playing and running around the
              house! Kittens at Annanoah are raised as members of our family
              until they become a member of yours. Breeding is free from genetic
              disease (HCM, PKD) and viral disease (FIV, FELV).
            </p>
            <div className="about-us__cat-img-container">
              <picture className="about-us__picture">
                <source media="(min-width: 800px)" srcSet={catL} />
                <source media="(min-width: 600px)" srcSet={catM} />
                <source media="(min-width: 480px)" srcSet={catS} />
                <source media="(min-width: 300px)" srcSet={catXS} />
                <img
                  className="about-us__img"
                  src={catXXS}
                  alt="Ragdoll cat is lying on the carpet"
                />
              </picture>
            </div>
            <p className="about-us__text">
              {/*  We breed ragdolls in the colors seal and blue in the varieties
              bicolor (high mitted) and mitted. You will not find Sepia,Minks or
              Solids with us, we do not support this variant. This variant is
              also not recognized by the worldwide organization FIFE. We only
              breed the original pointed ragdolls with blue eyes. */}
              Kittens that are in pet option are neutered with absolutely no
              compromise and are ready after they are 15 weeks old. We breed
              ragdolls in the colors seal and blue in the varieties bicolor
              (high mitted) and mitted. You will not find Sepia,Minks or Solids
              with us, we do not support this variant. This variant is also not
              recognized by the worldwide organization FIFE. We only breed the
              original pointed ragdolls with blue eyes.
            </p>
            <h3 className="about-us__text about-us__text--center">
              We use and recommend Rufi scratchers!!
            </h3>
            <a
              href="https://drapaki.pl/en/"
              target="_blank"
              title="Scatching for cats - drapaki.pl"
              rel="noreferrer noopener nofollow"
              style={{
                display: "grid",
              }}
            >
              <img
                alt="Drapaki.pl - Kratzbäume"
                src="https://drapaki.pl/images/banners/banner_800x120_en.png"
                className="about-us__img"
                style={{
                  borderWidth: "0px",
                  borderStyle: "solid",
                }}
              />
            </a>
          </article>
        </div>
      </main>
    </>
  );
}

export default AboutUs;
