import "../style/kittens.scss";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import kitten1M from "../img/kitten1M.webp";
import kitten1S from "../img/kitten1S.webp";
import kitten1XS from "../img/kitten1XS.webp";
import kitten2L from "../img/kitten4L.webp";
import kitten2M from "../img/kitten4M.webp";
import kitten2S from "../img/kitten4S.webp";
import kitten2XS from "../img/kitten4XS.webp";
import kitten2XXS from "../img/kitten4XXS.webp";
import kitten3M from "../img/kitten3M.webp";
import kitten3S from "../img/kitten3S.webp";
import kitten3XS from "../img/kitten3XS.webp";

function Kittens({ state, isKitten }) {
  const [litter] = useState(state);
  const history = useHistory();

  function handleClick() {
    history.push();
  }

  useEffect(() => {
    if (isKitten) {
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.add("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.add("menu__nav-link--yellow");
    } else if (!isKitten) {
      document.querySelectorAll(".menu__nav-link--kittens").forEach((item) => {
        item.textContent = "Kittens";
      });
      document
        .querySelector(".menu__nav-link--kittens--burger")
        .classList.remove("menu__nav-link--red");
      document
        .querySelector(".menu__nav-link--kittens--header")
        .classList.remove("menu__nav-link--yellow");
    }
  }, [isKitten]);

  let statusClass = "";
  let activeLitter = "";
  const litters = state
    .filter((litters) => litters.active)
    .map((litter) => {
      activeLitter = litter.litter;
      const kittensActive = litter.kittens.map((kitten) => {
        if (kitten.status === "booked") {
          statusClass =
            "kittens__text kittens__text-span kittens__status--booked";
        } else {
          statusClass =
            "kittens__text kittens__text-span kittens__status--available";
        }
        return (
          <li className="cat__item-litter" key={kitten.name} content={litter}>
            <h2 className="cat__title-litter">{kitten.name}</h2>
            <div className="cat__info-litter">
              <p className="kittens__text kittens__text-data">
                Date of Birth:{" "}
                <span className="kittens__text kittens__text-span">
                  {kitten.birth}
                </span>
              </p>
              <p className="kittens__text kittens__text-data">
                Color:{" "}
                <span className="kittens__text kittens__text-span">
                  {kitten.color}
                </span>
              </p>
              <p className="kittens__text kittens__text-data">
                Sex:{" "}
                <span className="kittens__text kittens__text-span">
                  {kitten.sex}
                </span>
              </p>
              <p className="kittens__text kittens__text-data">
                Status: <span className={statusClass}>{kitten.status}</span>
              </p>
            </div>
            <div className="cat__img-wrapper-litter">
              <Link
                target="_self"
                to={{
                  pathname: `/image/${kitten.link}`,
                  state: {
                    name: kitten.name,
                    profilePhoto: kitten.profilePhoto,
                    mainPhoto: kitten.mainPhoto,
                  },
                }}
              >
                <img
                  className="litters__img--link"
                  src={`${kitten.profilePhoto}`}
                  alt={`${kitten.name}`}
                />
              </Link>
            </div>
          </li>
        );
      });

      return (
        <li
          className="kittens__item-litter"
          key={litter.litter}
          content={litter}
        >
          <div className="kittens__parrents-wrapper">
            <h3 className="kittens__text kittens__important kittens__parentsA">
              Litter:{" "}
              <p className="kittens__text--big kittens__text--color">
                {litter.litter}
              </p>
            </h3>
            <h3 className="kittens__text kittens__important kittens__parentsB">
              Parents:
            </h3>
            <div className="kittens__mother-wrapper">
              <p className="kittens__text kittens__mother">{litter.mother}</p>
              <img
                className="kittens__img kittens__mother-img"
                src={litter.motherPhoto}
                alt={litter.mother}
              />
            </div>
            <div className="kittens__father-wrapper">
              <p className="kittens__text kittens__father">{litter.father}</p>
              <img
                className="kittens__img kittens__father-img"
                src={litter.fatherPhoto}
                alt={litter.father}
              />
            </div>
          </div>
          <ul className="kittens__list kittens__list--litters">
            {kittensActive}
          </ul>
        </li>
      );
    });

  function kittensAvailable() {
    return (
      <article className="kittens__litters kittens__litters--avtive kittens__section">
        <h2 className="kittens__text kittens__text--title">
          Our current litter:{" "}
          <p className="kittens__text--big kittens__text--color">
            {activeLitter}
          </p>
        </h2>
        <article className="kittens__reservation kittens__section">
          <h3 className="kittens__text kittens__important kittens__text--middle">
            We have a kittens !!!
          </h3>
          <div className="kittens__wrapper">
            <ul className="kittens__list kittens__list--litters">{litters}</ul>
          </div>
        </article>
        <div className="kittens__link-wrapper">
          <Link
            onClick={handleClick}
            className="kittens__link"
            to={{
              pathname: `/litters`,
              state: {
                litter,
              },
            }}
          >
            Previous Litters
          </Link>
        </div>
      </article>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          Kittens | Annanoah Ragdoll Cattery GB - Oxfordshire United Kingdom
        </title>
        <link rel="canonical" href="https://annanoahcattery.com/kittens" />
      </Helmet>
      <main className="kittens">
        <h1 className="kittens__title">Kittens</h1>
        {isKitten ? kittensAvailable() : null}
        <article className="kittens__reservation kittens__section">
          <h2 className="kittens__text kittens__important kittens__text--title">
            Reservation process
          </h2>
          <article className="kittens__reservation kittens__section">
            <p className="kittens__text">
              {/* We sign a sales agreement with each future kitten owner and take
              £500 non refundable deposit to secure a kitten of your choice.
              Remaining balance is required to be paid at the time of
              collection. */}
            </p>
          </article>
          <article className="kittens__our-kittens kittens__section">
            <div className="kittens__our-kittens-text">
              <p className="kittens__text">
                We offer pets, show alters as well as show / breeding, we breed
                selectively to produce Ragdolls of the highest quality, health
                is a priority and a perfect temperament. Kittens are raised in
                our home with children and dogs , they are exposed to day to day
                life and noises - exactly the same as can be in their new
                forever homes. We strongly believe that this approach is the
                best way to reflect Ragdoll temperament.
              </p>
              {/* <h3 className="kittens__text kittens__important">
                 All of my Ragdoll kittens leave us with:
              </h3> */}
              {/* <ul className="kittens__list">
                <li className="kittens__list-item">
                  5 generations FiFe Pedigree Certificate ( Federation
                  Internationale Feline)
                </li>
                <li className="kittens__list-item">Kitten Pack ( Starter )</li>
                <li className="kittens__list-item">
                  5 weeks petplan insurance
                </li>
                <li className="kittens__list-item">
                  Neutered does not apply to kittens purchased for breeding
                </li>
                <li className="kittens__list-item">
                  fully vaccinated and health checked
                </li>
                <li className="kittens__list-item">wormed and flea treated</li>
                <li className="kittens__list-item">microchipped</li>
                <li className="kittens__list-item">
                  pet passport (upon request at extra charge except kittens sold
                  outside UK)
                </li>
              </ul> */}
            </div>
            <div className="kittens__kitten-img-container">
              <picture className="about-us__picture">
                <source media="(min-width: 800px)" srcSet={kitten1M} />
                <source media="(min-width: 600px)" srcSet={kitten1S} />
                <img
                  className="kittens__img"
                  src={kitten1XS}
                  alt="Ragdoll kitten is looking up"
                />
              </picture>
            </div>
          </article>
          <article className="kittens__our2-kittens kittens__section">
            <div className="kittens__kitten-img-container">
              <picture className="about-us__picture">
                <source media="(min-width: 800px)" srcSet={kitten3M} />
                <source media="(min-width: 600px)" srcSet={kitten3S} />
                <img
                  className="kittens__img"
                  src={kitten3XS}
                  alt="Ragdoll kitten is looking up"
                />
              </picture>
            </div>
            <div className="kittens__our-kittens-text">
              <p className="kittens__text">
                Our top priority is to choose the best families for our kittens
                so they will be loved and find their permanent homes. Your
                kitten will be double vaccinated, 4 times health checked ,
                microchipped, dewormed also castrated. There is no compromise on
                neutering ! Kittens will be ready to move to their new home
                after 15 weeks of age with a 5 generation TICA pedigree
                certificate and high quality welcome food pack including 6kg dry
                food and plenty of wet food , printed vet health record and
                kitten insurance. We offer lifetime care and support for every
                kitten based on our experience in breeding since 2016.
              </p>
            </div>
          </article>
          <article className="kittens__conditions kittens__section">
            <div className="kittens__condition-text">
              <p className="kittens__text">
                All kittens that are booked overseas will have the relevant
                documents for travel also licensed transport by road and courier
                service to fly ( in the cabin ) to countries like USA, Canada ,
                United Arab Emirates and many more.
              </p>
              <p className="kittens__text">
                Please email{" "}
                <Link
                  to={{ pathname: "mailto:annanoah.gb@mail.com" }}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  annanoah.gb@mail.com
                </Link>{" "}
                to find out more information and a price for transportation
                service.
              </p>
              <p className="kittens__text">
                We sign a sales agreement with each future kitten owner and take
                £500 non refundable deposit to secure the kitten of your choice.
                The remaining balance is required to be paid at the time of
                collection.
              </p>
              {/* <h3 className="kittens__text kittens__important">
                Conditions and pricing
              </h3>
              <ul className="kittens__list">
                <li className="kittens__list-item">
                  Pet - kitten without breeding and show rights. The cat cannot
                  be reproduced or exhibited at cat shows.
                </li>
                <li className="kittens__list-item">
                  Show - not having breeding rights. A cat with the correct
                  markings and build. Without breeding rights, perfect in type
                  and able to participate in purebred cat shows
                </li>
                <li className="kittens__list-item">
                  Bread - with breeding rights, without defects in structure,
                  the dyeing method may be perfect or with slight color
                  imperfections
                </li>
                <li className="kittens__list-item">
                  Show-breed - cat with exemplary markings, perfect in type. A
                  cat in a show type with predisposition to breeding
                </li>
              </ul> */}
              <p className="kittens__text">
                Pet kittens are individually priced from £1500.
              </p>
              <p className="kittens__text">
                Show/breed are individually priced from £3000.
              </p>
            </div>
            <div className="kittens__kitten-img-container">
              <picture className="about-us__picture">
                <source media="(min-width: 800px)" srcSet={kitten2L} />
                <source media="(min-width: 600px)" srcSet={kitten2M} />
                <source media="(min-width: 400px)" srcSet={kitten2S} />
                <source media="(min-width: 250px)" srcSet={kitten2XS} />
                <img
                  className="kittens__img"
                  src={kitten2XXS}
                  alt="Ragdoll kitten is looking bak on the couch"
                />
              </picture>
            </div>
            <div className="kittens__link-wrapper">
              <Link
                onClick={handleClick}
                className="kittens__link"
                to={{
                  pathname: `/litters`,
                  state: {
                    litter,
                  },
                }}
              >
                Previous Litters
              </Link>
            </div>
          </article>
        </article>
      </main>
    </>
  );
}

export default Kittens;
